.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.editDoc .ant-tabs-nav .ant-tabs-nav-list {
  overflow-x: auto !important;
  padding-left: 10px;
  padding-right: 10px;
}

.editDoc .ant-tabs-nav .ant-tabs-nav-operations {
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-inline-collapsed .ant-menu-item,
.ant-menu-inline-collapsed .ant-menu-submenu-title {
  flex-direction: column;
}

.ant-menu-inline-collapsed .ant-menu-item {
  justify-content: center;
}

.ant-menu-inline-collapsed .ant-menu-item span.ant-menu-title-content {
  display: none !important;
}

.site-layout-background .ant-menu-item-selected {
  background-color: #7c4095 !important;
}

.ant-menu-item-selected span {
  color: #fff !important;
}

.ant-menu-item-selected .provider-menu {
  filter: brightness(2000%);
}

.ant-menu-item-selected>.ant-menu-title-content {
  color: #fff;
}

:where(.css-dev-only-do-not-override-gxe6w).ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0;
}

:where(.css-dev-only-do-not-override-gxe6w).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

.divider-row {
  background-color: #f5f5f5;
}

.divider-row:hover {
  background-color: #e8e8e8;
}