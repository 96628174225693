body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-anchor-link {
  margin: 0;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #f0f0f0;
  line-height: 2.8;
 
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#keyvaluepair label{
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  letter-spacing: 0.30px;
}
