.validation-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.validation-li {
    font-weight: normal;
    display: flex;
    width: 50%;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

.none-ul {
    display: none;
}

@media (max-width: 768px) {
    .validation-ul {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    .validation-ul .validation-li {
        margin-bottom: 10px;
        text-align: center;
        font-size: 10px;
    }
}